import * as React from 'react';
import { navigate } from 'gatsby';
import { Box, Typography, Button, Container } from '@mui/material';
import { Icon, Text } from '../components';
import { StandardLayout } from '../layouts';
import { useStaticQueryImages } from '../hooks/static-queries-hooks';

const title = `SWEAL`;
const subtitle = `Swipe your Meal! `;
const contentText = [
  `Generiere dein eigenes Gericht, kein lästiges
  eintippen, kein aufwendiges Suchen. Nur du, dein Gericht und das
  Lächeln auf deinem Gesicht.`,
];

export default function IndexPage() {
  const { headImg } = useStaticQueryImages();

  return (
    <StandardLayout
      imageProps={{
        imageData: headImg,
        alt: 'Landingpage Image, showing a lavish meal.',
      }}
      headerProps={{
        title,
        subtitle,
      }}
    >
      <Box p={4}>
        <Container maxWidth="sm">
          {contentText.map((c) => (
            <Text.Content.Text
              key={c
                .substring(0, c.length > 10 ? 10 : c.length)
                .replace(' ', '')}
              text={c}
            />
          ))}
          <Typography component="div" align="center">
            <Box m={1}>
              <Button
                size="large"
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => navigate('/login/')}
                endIcon={
                  <Icon iconName="mdiAccount" title="User Profile" size={1} />
                }
              >
                melde dich an
              </Button>
            </Box>
            <Box m={1}>
              <Button
                size="large"
                variant="outlined"
                color="primary"
                fullWidth
                onClick={() => navigate('/try-sweal/')}
                endIcon={
                  <Icon
                    iconName="mdiArrowRight"
                    title="User Profile"
                    size={1}
                  />
                }
              >
                probiere es aus
              </Button>
            </Box>
          </Typography>
        </Container>
      </Box>
    </StandardLayout>
  );
}
